import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getIntroductions,
  updateIntroductionStatusService,
} from "../services/introductionsService";

export const useIntroductions = () => {
  const [introductions, setIntroductions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [performedOutreachEvents, setPerformedOutreachEvents] = useState<
    string[]
  >([]);
  const { getAccessTokenSilently } = useAuth0();

  const fetchIntroductions = useCallback(async (searchName?: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const fetchedIntroductions = await getIntroductions(accessToken, searchName);
      setIntroductions(fetchedIntroductions);
    } catch (err) {
      setError("Failed to fetch introductions");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  const updateIntroductionStatus = useCallback(
    async (
      introductionId: string,
      performedOutreachEvents: string[],
      newStatus:
        | "pending"
        | "in progress"
        | "in progress (followed up)"
        | "accepted"
        | "rejected"
        | "stale"
    ) => {
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        await updateIntroductionStatusService(
          introductionId,
          performedOutreachEvents,
          newStatus,
          accessToken
        );
      } catch (err) {
        setError("Failed to update introduction status");
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  const updatePerformedOutreachEvents = (
    eventName: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setPerformedOutreachEvents([...performedOutreachEvents, eventName]);
    } else {
      setPerformedOutreachEvents(
        performedOutreachEvents.filter((event) => event !== eventName)
      );
    }
  };
  return {
    introductions,
    performedOutreachEvents,
    updatePerformedOutreachEvents,
    loading,
    error,
    fetchIntroductions,
    updateIntroductionStatus,
  };
};
