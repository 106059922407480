import { FC, useEffect, useState } from "react";
import { useAttractiveness } from "../../hooks/useAttractiveness";

const GridPage: FC = () => {
  const collectionName = "detailed_complete_women";
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);
  const {
    fetchGridPageImages,
    images,
    updateGridImagesSelected,
  } = useAttractiveness(collectionName);
  useEffect(() => {
    fetchGridPageImages();
  }, [fetchGridPageImages]);

  const handleLoadNewSet = () => {
    const allProfileIds = images.map(
      (prospectProfile) => prospectProfile.image_id
    );
    const nonSelectedProfileIds = allProfileIds.filter(
      (profile_id) => !selectedProfileIds.includes(profile_id)
    );

    setSelectedProfileIds([]);
    updateGridImagesSelected(selectedProfileIds, nonSelectedProfileIds);
    fetchGridPageImages();
  };

  return (
    <div>
      <div className="flex flex-row flex-wrap ">
        {/* {images.map((prospectProfile) => (
          <ProfileCard
            key={prospectProfile.image_id}
            // profile={prospectProfile}
            isSelected={selectedProfileIds.includes(prospectProfile.image_id)}
            onToggleSelect={() => {
              if (selectedProfileIds.includes(prospectProfile.image_id)) {
                setSelectedProfileIds(
                  selectedProfileIds.filter(
                    (id) => id !== prospectProfile.image_id
                  )
                );
              } else {
                setSelectedProfileIds([
                  ...selectedProfileIds,
                  prospectProfile.image_id,
                ]);
              }
            }}
          />
        ))} */}
      </div>
      <div className="flex flex-row justify-center my-8">
        <button
          onClick={handleLoadNewSet}
          className="bg-blue-500 text-white p-2 rounded-md"
        >
          Submit Selections and Load New Set
        </button>
      </div>
    </div>
  );
};

export default GridPage;
