import constants from "../constants";
import { ProspectProfile } from "../types";

export const findMatches = async (
  userId: string,
  accessToken: string,
  minAge?: number,
  maxAge?: number,
  matchingLocation?: string,
  religion?: string,
  minHeight?: number,
  maxHeight?: number,
  page?: number,
  requiredKeyAttributes?: string[],
  typeOfMember?: string
): Promise<Array<ProspectProfile>> => {
  const params = new URLSearchParams({
    user_id: userId,
  });

  if (minAge !== undefined) params.append("min_age", minAge.toString());
  if (maxAge !== undefined) params.append("max_age", maxAge.toString());
  if (matchingLocation !== undefined)
    params.append("matching_location", matchingLocation);
  if (religion !== undefined)
    params.append("religion", religion);
  if (minHeight !== undefined)
    params.append("min_height_inches", minHeight.toString());
  if (maxHeight !== undefined)
    params.append("max_height_inches", maxHeight.toString());
  if (page !== undefined) params.append("page", page.toString());
  if (requiredKeyAttributes && requiredKeyAttributes.length > 0)
    params.append("required_key_attributes", requiredKeyAttributes.join(","));
  if (typeOfMember !== undefined && typeOfMember !== "all")
    params.append("is_curate_member", (typeOfMember === "curate").toString());

  const response = await fetch(
    `${
      constants.BACKEND_API_ENDPOINT
    }/internal/findMatches?${params.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.json();
};

export const fetchNextImages = async (
  collectionName: string,
  n: number = 10
): Promise<Array<{ image_base64: string; image_id: string }>> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/nextImages?collection_name=${collectionName}&n=${n}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch images");
  }
  return response.json();
};

export const updateAttractivenessScore = async (
  imageId: string,
  score: number,
  collectionName: string
): Promise<void> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/updateAttractivenessScore`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image_id: imageId,
        score,
        auth_token: "changeit!temp",
        collection_name: collectionName,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update attractiveness score");
  }
  window.location.reload();
};

export const getGridPageImages = async (
  collectionName: string,
  accessToken: string
): Promise<Array<ProspectProfile>> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/getGridPageImages?collection_name=${collectionName}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch images");
  }
  return response.json();
};

export const updateGridImagesSelectedService = async (
  selectedProfileIds: string[],
  nonSelectedProfileIds: string[],
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/updateGridImagesSelected`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        selected_profile_ids: selectedProfileIds,
        non_selected_profile_ids: nonSelectedProfileIds,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update grid images selected");
  }
};

export const createMatchService = async (
  clientUserId: string,
  prospectUserId: string,
  accessToken: string
): Promise<string> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/createMatch`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        client_user_id: clientUserId,
        prospect_user_id: prospectUserId,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to create match");
  }
  return response.json();
};
