import React, { FC, useState, useEffect } from "react";

import Select from "react-select";
import { useIntroductions } from "../../hooks/useIntroductions";
import { useProspectDetails } from "../../hooks/useProspectDetails";
import Modal from "../../components/Modal";
import ProspectSummary from "./ProspectSummary";

interface Introduction {
  _id: string;
  client_user_id: string;
  status: "pending" | "in progress" | "accepted" | "rejected";
  match_id: string;
  prospect_is_curate_member: boolean;
  prospect_profile_id: string | null;
  prospect_summary: string | null;
  created_at: string;
  prospect_base64_image: string | null;
  client_bio: string | null;
}

const statusOptions = [
  { value: "pending", label: "Pending" },
  { value: "in progress", label: "In Progress" },
  { value: "in progress (followed up)", label: "In Progress (Followed Up)" },
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];

const Introductions: FC = () => {
  const {
    introductions,
    loading,
    error,
    performedOutreachEvents,
    updatePerformedOutreachEvents,
    fetchIntroductions,
    updateIntroductionStatus,
  } = useIntroductions();

  const {
    prospectInstagramHandle,
    prospectLinkedinUrl,
    prospectEmail,
    prospectPhoneNumber,
    setProspectInstagramHandle,
    setProspectLinkedinUrl,
    setProspectEmail,
    setProspectPhoneNumber,
    fetchProspectDetails,
    updateProspectDetails,
  } = useProspectDetails(); // New hook

  const [selectedProspect, setSelectedProspect] = useState<Introduction | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBioModalOpen, setIsBioModalOpen] = useState(false);
  const [selectedBio, setSelectedBio] = useState<string | null>(null);
  const [searchName, setSearchName] = useState(new URLSearchParams(window.location.search).get('s') || "");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get('s');
    fetchIntroductions(searchParam || undefined);
  }, [fetchIntroductions]);

  const handleStatusChange = async (
    introductionId: string,
    newStatus: string
  ) => {
    await updateIntroductionStatus(
      introductionId,
      performedOutreachEvents,
      newStatus as
        | "pending"
        | "in progress"
        | "in progress (followed up)"
        | "accepted"
        | "rejected"
        | "stale"
    );
    fetchIntroductions();
  };

  const handleSearchByName = async () => {
    const params = new URLSearchParams(window.location.search);
    params.set('s', searchName);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    fetchIntroductions(searchName);
  };

  const handleClear = () => {
    setSearchName("");
    const params = new URLSearchParams(window.location.search);
    params.delete('s');
    window.history.replaceState({}, '', window.location.pathname);
    fetchIntroductions();
  };

  const handleOpenModal = async (prospect: Introduction) => {
    if (prospect.prospect_profile_id) {
      await fetchProspectDetails(prospect.prospect_profile_id);
    }
    setSelectedProspect(prospect);
    setIsModalOpen(true);
  };

  const handleUpdateProspectDetails = async () => {
    if (selectedProspect && selectedProspect.prospect_profile_id) {
      await updateProspectDetails(selectedProspect.prospect_profile_id, {
        email: prospectEmail,
        phone_number: prospectPhoneNumber,
        instagram_handle: prospectInstagramHandle,
        linkedin: prospectLinkedinUrl,
      });
      setIsModalOpen(false);
    }
  };

  const handleOpenBioModal = (bio: string) => {
    setSelectedBio(bio);
    setIsBioModalOpen(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Introductions</h1>
      <form
        className="flex gap-2 mb-4 w-1/2"
        onSubmit={(e) => {
          e.preventDefault();
          if (searchName.trim()) {
            handleSearchByName();
          }
        }}
      >
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search prospect or member..."
            className="border rounded px-3 py-2 w-full"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className={`${!searchName.trim() ? 'bg-gray-400' : 'bg-blue-500'} text-white px-4 py-2 rounded flex items-center gap-2`}
          disabled={!searchName.trim()}
        >
          Search
        </button>
        {searchName.trim() && (
          <button
            type="button"
            className="bg-gray-500 text-white px-4 py-2 rounded flex items-center gap-2 ml-2"
            onClick={() => handleClear()}
          >
            Clear
          </button>
        )}
      </form>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border p-2">Prospect Photo</th>
            <th className="border p-2">Client Bio</th>
            <th className="border p-2 w-[25px]">Match ID</th>
            <th className="border p-2 min-w-[300px]">Prospect Summary</th>
            <th className="border p-2">Created At</th>
            <th className="border p-2">Status</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {introductions.map((intro: Introduction) => (
            <tr key={intro._id}>
              <td className="border p-2 min-w-[220px]">
                <img
                  src={`data:image/jpeg;base64,${intro.prospect_base64_image}`}
                  alt="Prospect Photo"
                  className="w-[200px] h-[200px]"
                />
              </td>
              <td className="border p-2">
                {(intro.client_bio || "N/A").split(" ").slice(0, 20).join(" ")}
                {intro.client_bio &&
                  intro.client_bio.split(" ").length > 20 &&
                  "..."}
                {intro.client_bio && (
                  <>
                    <button
                      onClick={() =>
                        navigator.clipboard.writeText(intro.client_bio || "")
                      }
                      className="ml-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
                    >
                      Copy
                    </button>
                    <button
                      onClick={() => handleOpenBioModal(intro.client_bio || "")}
                      className="ml-2 bg-green-500 text-white px-2 py-1 rounded text-sm"
                    >
                      View
                    </button>
                  </>
                )}
              </td>
              <td className="border p-2 text-xs">
                {intro.match_id.split("").map((char, index) => (
                  <React.Fragment key={index}>
                    {char}
                    {(index + 1) % 4 === 0 && <br />}
                  </React.Fragment>
                ))}
              </td>
              <td className="border p-2" style={{ width: "300px" }}>
                <ProspectSummary summary={intro.prospect_summary || ""} />
              </td>
              <td className="border p-2">
                {new Date(intro.created_at).toLocaleString()}
              </td>
              <td className="border p-2" style={{ width: "200px" }}>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === intro.status
                  )}
                  onChange={(option) =>
                    handleStatusChange(intro._id, option?.value || "")
                  }
                />
              </td>
              <td className="border p-2">
                <button onClick={() => handleOpenModal(intro)}>
                  View Contact Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedProspect && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Contact Details"
        >
          <h2 className="text-xl font-semibold mb-4">Contact Details</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="text"
              value={prospectEmail || ""}
              onChange={(e) => setProspectEmail(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Phone Number
            </label>
            <input
              type="text"
              value={prospectPhoneNumber || ""}
              onChange={(e) => setProspectPhoneNumber(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Instagram Handle
            </label>
            <input
              type="text"
              value={prospectInstagramHandle || ""}
              onChange={(e) => setProspectInstagramHandle(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              LinkedIn URL
            </label>
            <input
              type="text"
              value={prospectLinkedinUrl || ""}
              onChange={(e) => setProspectLinkedinUrl(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Actions</h3>
            <div className="grid grid-cols-2 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent email 1")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent email 1",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent email 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent email 2")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent email 2",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent email 2
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent SMS 1")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent SMS 1",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent SMS 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent SMS 2")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent SMS 2",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent SMS 2
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Followed on IG")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Followed on IG",
                      e.target.checked
                    )
                  }
                />{" "}
                Followed on IG
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent IG message 1"
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent IG message 1",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent IG message 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent IG message 2"
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent IG message 2",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent IG message 2
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Connected on LinkedIn"
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Connected on LinkedIn",
                      e.target.checked
                    )
                  }
                />{" "}
                Connected on LinkedIn
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent LinkedIn Message 1"
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent LinkedIn Message 1",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent LinkedIn Message 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent LinkedIn Message 2"
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent LinkedIn Message 2",
                      e.target.checked
                    )
                  }
                />{" "}
                Sent LinkedIn Message 2
              </label>
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleUpdateProspectDetails}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Update
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 bg-gray-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      {isBioModalOpen && selectedBio && (
        <Modal
          isOpen={isBioModalOpen}
          onClose={() => setIsBioModalOpen(false)}
          title="Client Bio"
        >
          <p className="whitespace-pre-wrap">{selectedBio}</p>
        </Modal>
      )}
    </div>
  );
};

export default Introductions;
