import { useState, useCallback, useEffect } from "react";
import {
  fetchNextImages,
  findMatches,
  getGridPageImages,
  updateAttractivenessScore,
  updateGridImagesSelectedService,
  createMatchService,
} from "../services/attractivenessService";
import { ProspectProfile } from "../types";
import { useAuth0 } from "@auth0/auth0-react";

export const useAttractiveness = (collectionName: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [matches, setMatches] = useState<ProspectProfile[]>([]);
  const [images, setImages] = useState<
    { image_base64: string; image_id: string }[]
  >([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchMatches = useCallback(
    async (
      userId: string | undefined,
      minAge?: number,
      maxAge?: number,
      matchingLocation?: string,
      religion?: string,
      minHeight?: number,
      maxHeight?: number,
      page?: number,
      requiredKeyAttributes?: string[],
      typeOfMember?: string
    ) => {
      if (!userId) return;
      setIsLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        const fetchedMatches = await findMatches(
          userId,
          accessToken,
          minAge,
          maxAge,
          matchingLocation,
          religion,
          minHeight,
          maxHeight,
          page,
          requiredKeyAttributes,
          typeOfMember
        );
        setMatches(fetchedMatches);
      } catch (err) {
        setError("Failed to fetch matches");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  const fetchGridPageImages = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const newImages = await getGridPageImages(collectionName, accessToken);
      console.log(newImages[0]);
      setImages(newImages);
    } catch (err) {
      setError("Failed to fetch images");
    } finally {
      setIsLoading(false);
    }
  }, [collectionName]);

  const updateGridImagesSelected = useCallback(
    async (selectedProfileIds: string[], nonSelectedProfileIds: string[]) => {
      const accessToken = await getAccessTokenSilently();
      await updateGridImagesSelectedService(
        selectedProfileIds,
        nonSelectedProfileIds,
        accessToken
      );
    },
    []
  );

  const fetchImages = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const newImages = await fetchNextImages(collectionName);
      setImages((prevImages) => [
        ...prevImages,
        ...(newImages as ProspectProfile[]),
      ]);
    } catch (err) {
      setError("Failed to fetch images");
    } finally {
      // console.log(images.length);
      setIsLoading(false);
    }
  }, [collectionName]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const rateImage = useCallback(
    async (score: number) => {
      if (currentImageIndex >= images.length) return;

      const currentImage = images[currentImageIndex];
      try {
        await updateAttractivenessScore(
          currentImage.image_id,
          score,
          collectionName
        );
        setCurrentImageIndex((prevIndex) => prevIndex + 1);
        if (currentImageIndex + 1 >= images.length) {
          fetchImages();
        }
      } catch (err) {
        setError("Failed to update attractiveness score");
      }
    },
    [currentImageIndex, images, fetchImages]
  );

  const createMatch = useCallback(
    async (clientUserId: string, prospectUserId: string) => {
      try {
        const accessToken = await getAccessTokenSilently();
        const result = await createMatchService(
          clientUserId,
          prospectUserId,
          accessToken
        );
        // You might want to update the matches state or refetch matches here
        return result;
      } catch (error) {
        console.error("Error creating match:", error);
        throw error;
      }
    },
    [getAccessTokenSilently]
  );

  const clearMatches = useCallback(() => {
    setMatches([]);
  }, []);

  return {
    matches,
    fetchGridPageImages,
    fetchMatches,
    images,
    currentImage: images[currentImageIndex],
    rateImage,
    isLoading,
    error,
    updateGridImagesSelected,
    createMatch,
    clearMatches,
  };
};

function useAuth(): { getAccessTokenSilently: any } {
  throw new Error("Function not implemented.");
}
