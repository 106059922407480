import React, { FC, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAttractiveness } from "../../hooks/useAttractiveness";
import ProfileCard from "./ProfileCard";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinbox from "../../components/UICore/LoadingSpinbox";
import constants from "../../constants";

const FindMatches: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [minAge, setMinAge] = useState<number | undefined>(undefined);
  const [maxAge, setMaxAge] = useState<number | undefined>(undefined);
  const [matchingLocation, setMatchingLocation] = useState<string | undefined>(
    undefined
  );
  const [religion, setReligion] = useState<string | undefined>(
    undefined
  );
  const [minHeight, setMinHeight] = useState<number | undefined>(undefined);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const [requiredKeyAttributes, setRequiredKeyAttributes] = useState<string[]>(
    []
  ); // Clear selected attributes
  const [typeOfMember, setTypeOfMember] = useState<string>("");

  const [areChangesToQuery, setAreChangesToQuery] = useState(false);
  const [page, setPage] = useState(1);
  const { matches, fetchMatches, isLoading, createMatch } =
    useAttractiveness("detailed_complete_women");

  const isInitialRender = useRef(true);

  useEffect(() => {
    fetchMatches(
      id,
      minAge,
      maxAge,
      matchingLocation,
      religion,
      minHeight,
      maxHeight,
      page,
      requiredKeyAttributes,
      typeOfMember
    );
  }, [page]);

  useEffect(() => {
    if (isInitialRender.current) {
      // fetchMatches(
      //   id,
      //   minAge,
      //   maxAge,
      //   matchingLocation,
      //   minHeight,
      //   maxHeight,
      //   page
      // );
      isInitialRender.current = false;
      return;
    }
    if (minAge || maxAge || matchingLocation || religion || typeOfMember || minHeight || maxHeight) {
      setAreChangesToQuery(true);
    }
  }, [minAge, maxAge, matchingLocation, religion, typeOfMember, minHeight, maxHeight]);

  const handleFetchUserWithQuery = () => {
    fetchMatches(
      id,
      minAge,
      maxAge,
      matchingLocation,
      religion,
      minHeight,
      maxHeight,
      page,
      requiredKeyAttributes,
      typeOfMember
    );
    setAreChangesToQuery(false);
  };

  const handleCreateMatch = async (prospectUserId: string) => {
    try {
      alert("Creating match");
      await createMatch(id || "", prospectUserId);

      alert("Match created");
      fetchMatches(
        id,
        minAge,
        maxAge,
        matchingLocation,
        religion,
        minHeight,
        maxHeight,
        page,
        requiredKeyAttributes,
        typeOfMember
      );
    } catch (error) {
      console.error("Error creating match:", error);
      alert(`Error creating match: ${error}`);
    }
  };

  const handleClearSearch = () => {
    setMinAge(undefined);
    setMaxAge(undefined);
    setMatchingLocation(undefined);
    setReligion(undefined);
    setMinHeight(undefined);
    setMaxHeight(undefined);
    setTypeOfMember("");
    setRequiredKeyAttributes([]); // Clear selected attributes
    setAreChangesToQuery(false);
    fetchMatches(id);
  };

  if (isLoading) {
    return <LoadingSpinbox textBelow="Loading matches..." />;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Matches</h1>
      <div className="mb-4 flex flex-wrap gap-4">
        <input
          type="number"
          placeholder="Min Age"
          value={minAge || ""}
          onChange={(e) =>
            setMinAge(e.target.value ? Number(e.target.value) : undefined)
          }
          className="border p-2 rounded"
        />
        <input
          type="number"
          placeholder="Max Age"
          value={maxAge || ""}
          onChange={(e) =>
            setMaxAge(e.target.value ? Number(e.target.value) : undefined)
          }
          className="border p-2 rounded"
        />
        <select
          value={matchingLocation || ""}
          onChange={(e) => setMatchingLocation(e.target.value || undefined)}
          className="border p-2 rounded"
        >
          <option value="">Select Location</option>
          <option value="LA">LA</option>
          <option value="New York">New York</option>
          <option value="San Francisco">San Francisco</option>
        </select>
        <select
          value={religion || ""}
          onChange={(e) => setReligion(e.target.value || undefined)}
          className="border p-2 rounded"
        >
          <option value="">Select Religion</option>
          <option value="agnosticism">Agnosticism</option>
          <option value="atheism">Atheism</option>
          <option value="buddhism">Buddhism</option>
          <option value="catholicism">Catholicism</option>
          <option value="christianity">Christianity</option>
          <option value="hinduism">Hinduism</option>
          <option value="islam">Islam</option>
          <option value="judaism">Judaism</option>
          <option value="unitarianism">Unitarianism</option>
          <option value="other">Other</option>
        </select>
        <input
          type="number"
          placeholder="Min Height (inches)"
          value={minHeight || ""}
          onChange={(e) =>
            setMinHeight(e.target.value ? Number(e.target.value) : undefined)
          }
          className="border p-2 rounded"
        />
        <input
          type="number"
          placeholder="Max Height (inches)"
          value={maxHeight || ""}
          onChange={(e) =>
            setMaxHeight(e.target.value ? Number(e.target.value) : undefined)
          }
          className="border p-2 rounded"
        />
        <select
          value={typeOfMember}
          onChange={(e) => setTypeOfMember(e.target.value)}
          className="border p-2 rounded"
        >
          <option value="">Type of Member</option>
          <option value="all">All</option>
          <option value="curate">Curate Member</option>
          <option value="non-curate">Non Curate Member</option>
        </select>
        {constants.OPTIONAL_KEY_ATTRIBUTES.map((attribute) => (
          <div
            key={attribute}
            className={`${
              requiredKeyAttributes.includes(attribute)
                ? "bg-indigo-500"
                : "bg-indigo-300"
            } text-white p-2 rounded cursor-pointer`}
            onClick={() => {
              setRequiredKeyAttributes((prev) =>
                prev.includes(attribute)
                  ? prev.filter((attr) => attr !== attribute)
                  : [...prev, attribute]
              );
              setAreChangesToQuery(true);
            }}
          >
            {attribute}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap gap-4 flex-row">
        {areChangesToQuery && (
          <button
            onClick={handleFetchUserWithQuery}
            className="bg-blue-500 text-white p-2 rounded"
          >
            Fetch Matches
          </button>
        )}
        <button
          onClick={handleClearSearch}
          className="bg-gray-500 text-white p-2 rounded"
        >
          Clear Search
        </button>
      </div>
      <div className="flex flex-wrap">
        {matches.map((match) => (
          <ProfileCard
            key={match._id}
            profile={match}
            onSelect={() => handleCreateMatch(match._id)}
          />
        ))}
      </div>
      <div className="flex w-full items-center justify-center">
        <button
          className="bg-blue-500 text-white p-2 rounded"
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Show Next Set
        </button>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(FindMatches, {
  returnTo: "/",
  onRedirecting: () => <LoadingSpinbox textBelow="Authenticating..." />,
});
