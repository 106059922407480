import constants from "../constants";
import { User } from "../types";

export const generateMatchService = async (
  phoneNumber: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/matches/find_personalized_match`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ phone_number: phoneNumber }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to generate match");
  }
  return response.json();
};

export const setHideNeedsMatchAlertsService = async (
  phoneNumber: string,
  hideNeedsMatchAlerts: boolean,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/toggleNeedsMatch`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
        value: hideNeedsMatchAlerts,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to toggle needs match");
  }
  return response.json();
};

export const archiveUserService = async (
  userId: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/archiveUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user_id: userId }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to archive user");
  }
  return response.json();
};

export const archiveMatchService = async (
  matchId: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/archiveMatch`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ match_id: matchId }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to archive match");
  }
  return response.json();
};

export const modifyMatchNoteOrBioService = async (
  matchId: string,
  note: string,
  bio: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/modifyMatchNoteOrBio`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ match_id: matchId, note: note, bio: bio }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to modify match note or bio");
  }
  return response.json();
};

export const getUsers = async (
  query: string,
  accessToken: string,
  sortBy: string,
  filterByMustHave: string[],
  filterByMustNotHave: string[]
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/users`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_query: query,
        sort_by: sortBy,
        filter_by_must_have: filterByMustHave,
        filter_by_must_not_have: filterByMustNotHave,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch users");
  }
  return response.json();
};

export const getUser = async (
  userId: string,
  accessToken: string
): Promise<User> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch user");
  }
  return response.json();
};

export const requestAnotherMatchService = async (
  phoneNumber: string,
  accessToken: string
) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/internal/requestAnotherMatch?phone_number=${phoneNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to request another match");
    }

    return await response.json();
  } catch (error) {
    console.error("Error requesting another match:", error);
    throw error;
  }
};

export const toggleAutomatedResponseService = async (
  phoneNumber: string,
  accessToken: string
): Promise<User> => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/internal/toggleAutomatedResponse`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to toggle automated response");
    }

    return await response.json();
  } catch (error) {
    console.error("Error toggling automated response:", error);
    throw error;
  }
};
export const sendMatchService = async (
  matchId: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/sendMatch`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ match_id: matchId }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to send match");
  }
  return response.json();
};

export const createIntroductionService = async (
  matchId: string,
  accessToken: string
) => {
  alert("Creating introduction");
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/matches/createIntroduction`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ match_id: matchId }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to create introduction");
  }
  alert("Introduction created");
};
